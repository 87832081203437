function handleCaptchaRefresh() {
  const refreshBtns = document.querySelectorAll('.btn_refresh');

  refreshBtns.forEach((btn) => {
    const refreshBtn = btn;
    refreshBtn.addEventListener('click', loadCaptcha);
  });
}

function loadCaptcha() {
  const captcha = new Image();
  const param = Math.random().toString(36).slice(-5);
  captcha.src = `/service/captcha?rnd=${param}`;
  captcha.className = 'form-block__captcha-img';
  captcha.alt = 'captcha';
  captcha.id = 'captcha-img';
  captcha.height = 52;
  $('.form-block__captcha-container').html(captcha);

  handleCaptchaRefresh();
}

export default loadCaptcha;
