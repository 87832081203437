import 'slick-carousel';
import { loadGamesForHall, insertFavouriteGames } from '../game-hall/game-hall';

export function initMenuSlider() {
    $(document).ready(() => {
        const arrowRight = '<div class="slider-arrow-wrapp slider-arrow-wrapp__right"><svg viewBox="0 0 100 171.385" class="icon icon_arrow-right"><path d="M0 14.462q0 2.153 1.846 3.692l67.385 67.692-67.385 67.385Q0 155.077 0 157.23t1.846 4l8.616 8.615q1.538 1.539 3.692 1.539t4-1.539l80-80.308Q100 88 100 85.846t-1.846-4l-80-80Q16.615 0 14.154 0q-2.462 0-3.692 1.846l-8.616 8.616Q0 12 0 14.462z"></path></svg></div>'

        const arrowLeft = '<div class="slider-arrow-wrapp slider-arrow-wrapp__left"><svg viewBox="0 0 100 171.385" class="icon icon_arrow-left"><path d="M100 156.923q0-2.154-1.846-3.692L30.769 85.538l67.385-67.384q1.846-1.846 1.846-4t-1.846-4l-8.616-8.616Q88 0 85.846 0t-4 1.538l-80 80.308Q0 83.385 0 85.538q0 2.154 1.846 4l80 80q1.539 1.847 4 1.847 2.462 0 3.692-1.847l8.616-8.615q1.846-1.538 1.846-4z"></path></svg></div>'

        $('.slider-hall').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            swipe: false,
            fade: true,
            asNavFor: '.slider-nav',
        });

        $('.slider-nav').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            prevArrow: arrowLeft,
            nextArrow: arrowRight,
            asNavFor: '.slider-hall',
            focusOnSelect: true,
        });


        $('.slider-hall').on('afterChange', () => {
            const hall = document.querySelector('.slider-hall .slick-current').id;

            // Hide other halls cause they height may be greater than current one
            if (hall !== 'all-games') {
                hall === 'favourite-games'
                    ? insertFavouriteGames()
                    : loadGamesForHall(hall);
            }

            $('.slider-hall').find('.slick-slide:not(.slick-current)').height('0');
            $('.slider-hall').find('.slick-slide.slick-current').height('auto');
        });
    });
}
