import 'magnific-popup';
import axios from 'axios';
import { i18n, currencyCode } from '../i18n/i18n';
import initPopup, { openDynamicPopup } from '../popup/popup';
import { GAME_IMG_DEF } from '../../../../js/constants';

/**
 * Convert attributes from JSON to string
 * @param {{}} obj - Attributes
 * @return {string} - Attributes for markup
 */
function getAllAttributes(obj) {
    let str = '';
    for (const prop in obj) {
        if( obj.hasOwnProperty( prop ) ) {
            str += `${prop}="${obj[prop]}" `
        }
    }
    return str;
}

/**
 * Check attributes presence for game
 * @param {{}} game - Game object
 * @param {string} [imgType = 'logo'] - Type of image
 * @return {{}} - Attributes in JSON
 */
function checkAttributes(game, imgType) {
    if (game.images[imgType] &&
        game.images[imgType].attributes &&
        !$.isEmptyObject(game.images[imgType].attributes)) {
        return game.images[imgType].attributes;
    }

    return {'alt': game.name};
}

/**
 * Check image of given type exist
 * @param {{}} game - Game object
 * @param {string} [imgType = 'logo'] - Type of image
 * @return {boolean} - is image of given type exist
 */
function checkImageExist(game, imgType) {
    return (
        !$.isEmptyObject(game.images) &&
        !$.isEmptyObject(game.images[imgType]) &&
        game.images[imgType].link !== ''
    )
}

/**
 * Add and remove game to/from favorite
 */
function toggleGameFavotite(toggler) {
  function changeGame(isFav) {
    toggler.setAttribute('data-infavorite', isFav);
    const title = 'Избранное';
    let addedToFav;
    if (!isFav) {
      removeGame(toggler);
      addedToFav = ' удалена из избранного';
      toggler.classList.remove('active');
    } else {
      toggler.classList.add('active');
      cloneGame(toggler);
      addedToFav = ' добавлена в избранное';
    }
    const message = `Игра ${toggler.dataset.name}${addedToFav}`;
    openDynamicPopup(title, message);
    initPopup();
  }

  function removeGame(game) {
    // remove from favorite tab
    $('#favourite-games')
      .find(`.game__favorite[data-code="${game.dataset.code}"]`)
      .closest('.game')
      .remove();
    // not full all hearts on page
    $(`.game__favorite[data-code="${game.dataset.code}"]`)
      .removeClass('active')
      .attr('data-infavorite', 'false');
  }

  function cloneGame(game) {
    $('#favourite-games .fav-list-empty').remove();
    const clonedGame = $(game)
      .closest('.game')
      .clone(true)
      .appendTo('#favourite-games .game-hall__wrapp');
    clonedGame.find('.game__favorite').removeClass('event-fav');
    clonedGame.appendTo('#favourite-games .game-hall__wrapp');
    $(`.game__favorite[data-code="${game.dataset.code}"]`)
      .addClass('active)')
      .attr('data-infavorite', 'true');
    // add event to new element
    onFavourClick();
  }

  const sendingData = {};
  sendingData.code = toggler.dataset.code;
  sendingData.provider = toggler.dataset.prov;
  const isFavorite = toggler.dataset.infavorite;
  if (isFavorite === 'true') {
    axios
      .post('/service/player/game/remove', sendingData)
      .then(response => response.data)
      .then(
        res =>
          res.data
            ? changeGame(false)
            : openDynamicPopup('Ошибка', res.error.items[Object.keys(res.error.items)[0]]),
      )
      .catch(error => alert(error));
  } else {
    axios
      .post('/service/player/game/add', sendingData)
      .then(response => response.data)
      .then(
        res =>
          res.data
            ? changeGame(true)
            : openDynamicPopup('Ошибка', res.error.items[Object.keys(res.error.items)[0]]),
      )
      .catch(error => alert(error));
  }
}

function getGameLink(mode, gameCode, gameProvider) {
  axios.get(`/service/game/${mode}/${gameCode}/${gameProvider}/html5/null`).then(({ data: { data: gameLink } }) => {
    if (gameLink) {
      location.href = gameLink;
    }
  });
}

export function onFavourClick() {
  const hearts = [...document.querySelectorAll('.game__favorite:not(.event-fav)')];

  hearts.forEach((heart) => {
    heart.addEventListener('click', (e) => {
      e.preventDefault();
      toggleGameFavotite(heart);
    });
    heart.classList.add('event-fav');
  });
}

export function openGamePopup() {
  $(document).ready(() => {
    $('.game-popup-link').magnificPopup({
      type: 'inline',
      midClick: true,
      mainClass: 'mfp-fade',
      fixedBgPos: true,
      fixedContentPos: true,
      items: {
        src: '#game-popup',
      },
      callbacks: {
        open() {
          const imgPath = $(this.st.el)
            .closest('.game__wrapp')
            .attr('data-img');
          const gameCode = $(this.st.el)
            .closest('.game__wrapp')
            .attr('data-code');
          const gameProvider = $(this.st.el)
            .closest('.game__wrapp')
            .attr('data-prov');
          const gameBets = $(this.st.el)
            .closest('.game__wrapp')
            .attr('data-bets');
          const rulesLink = `/game/${window.userLogged ? 'real' : 'demo'}/${gameProvider}/${gameCode}`;
          const gameName = $(this.st.el)
            .closest('.game__wrapp')
            .attr('data-name');

          $('#gamePopup_rulesLink').attr('href', rulesLink);
          $('#gamePopup_gameName').text(gameName);
          $('#gamePopup_imgPath').attr({ src: imgPath, alt: gameName });
          $('#gamePopup_gameBets').text(`${(parseFloat(gameBets)).toFixed().toLocaleString()} ${i18n.t(currencyCode)}`);
          $('.play-game-btn')
            .attr('data-code', gameCode)
            .attr('data-prov', gameProvider);
          if (window.userLogged) {
            $('#gamePopup_moneyLink').attr('href', '#');
          } else {
            $('#gamePopup_moneyLink')
              .attr('href', '/registration')
              .text('Играть на деньги');
          }
          document.body.classList.add('modal-opened');
        },
        close: () => document.body.classList.remove('modal-opened'),
      },
    });
  });
}

export function setPlayButtons() {
  $(document).on('click', '.play-game-btn', (e) => {
    const button = e.currentTarget;
    const gameCode = button.dataset.code;
    const gameMode = button.dataset.mode;
    const gameProvider = button.dataset.prov;
    if (gameMode !== 'null') {
      e.preventDefault();
      getGameLink(gameMode, gameCode, gameProvider);
    }
  });
}

export function initGame() {
  openGamePopup();
  onFavourClick();
  setPlayButtons();
}

export function renderGame(game) {
  const imgPath = checkImageExist(game, 'logo')
    ? game.images.logo.link
    : GAME_IMG_DEF;
  const attributes = getAllAttributes(checkAttributes(game, 'logo'));
  const favButton = window.userLogged
    ? `<div data-code="${game.code}"
          data-prov="${game.providerCode}"
          data-infavorite="${game.favorite ? 'true' : 'false'}"
          data-name="${game.name}"
          class="game__favorite ${game.favorite ? 'active' : ''}"
          >
          <svg viewBox="0 0 100 87.5" class="icon icon_heart"><path d="M90.294 5.983C79.59-3.138 63.673-1.497 53.849 8.639l-3.848 3.965-3.847-3.965C36.349-1.497 20.412-3.138 9.709 5.983-2.556 16.45-3.2 35.24 7.776 46.588L45.568 85.61a6.123 6.123 0 008.847 0l37.793-39.022c10.996-11.348 10.351-30.137-1.914-40.605z"></path></svg>
        </div>`
    : '';
  const gameTitle = game.mobile 
    ? `<a href="#game-popup" class="game__title game-popup-link">${game.name}</a>`
    : `<span class="game__title">${game.name}</span>`;

  const gameContent = game.mobile
    ? `<a href="#game-popup" class="game__img-wrapp game-popup-link">
        <img src="${imgPath}" ${attributes}>
      </a>`
    : `<div class="game__img-wrapp">
        <div class="game__not-mobile">
          <p>Игра доступна в полной версии сайта</p>
        </div>
        <img src="${imgPath}" ${attributes}>
      </div>`;

  return $(
    `<div class="game">
      <div
        data-bets="${game.bets}"
        data-img="${imgPath}"
        data-name="${game.name}"
        data-code="${game.code}"
        data-prov="${game.providerCode}"
        data-infavorite="${game.favorite ? 'true' : 'false'}"
        class="game__wrapp"
      >
        <div class="game__header">
          ${gameTitle}
          ${favButton}
        </div>
        ${gameContent}
      </div>
    </div>`);
}