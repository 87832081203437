import 'magnific-popup';
import 'slick-carousel';
import { get } from 'axios';
import { onFavourClick, openGamePopup, renderGame, setPlayButtons } from '../game/game';
import { initMenuSlider } from '../menu-slider/menu-slider';
import { closeMainMenu } from '../main-menu/main-menu';

let favLoaded = false;

// lazy load vars
let allGamesArray = [];
let allGamesByHall = [];
let allGamesObj = {};
let countGames = countGamesOnScreen();
let lastGame = countGames;

function onMainMenuHallClick() {
    const mainMenuProviders = document.querySelector('.main-menu__submenu_providers');

    mainMenuProviders.addEventListener('click', (e) => {
        e.preventDefault();
        let target = e.target;
        while (target !== mainMenuProviders) {
            if (target.classList.contains('main-menu__item')) {
                const hall = target.dataset.hall;
                const $hall = $(`.slider-menu__item[data-hall="${hall}"]`).not('.slick-cloned');
                $hall.find('a').click();
                history.replaceState(null, null, location.protocol + '//' + location.host + location.pathname + '?hall=' + hall);
                $('html, body').animate({ scrollTop: 0 }, 300);

                if (hall === 'favourite-games') {
                    requestInsertFavouriteGames();
                }
                setTimeout(() => {
                    closeMainMenu();
                }, 300);
            }
            target = target.parentNode;
        }
    });
}

function getUrlParameter(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

function detectGameHall() {
  const menuItem = getUrlParameter('hall');
  if (menuItem === undefined) {
    return false;
  }
  const forMenu = menuItem.replace('#', '');
  $(`.slider-menu__item[data-hall="${forMenu}"]`).not('.slick-cloned').click();
}

export function getAllGameList() {
  get('/service/games?type=mobile')
    .then((response) => {
      allGamesArray = response.data;
      loadSliceGames(0, countGames); // first load 10 games

      if (allGamesArray.length > countGames) {
        $(window).on('resize', windowResize);
        $(window).on('scroll', windowScrolling);
      }
    })
    .then(() => {
      setTimeout(() => {
        onMainMenuHallClick();
        initMenuSlider();
        detectGameHall();
        setPlayButtons();
      }, 100);
    });
}

export function getGameListByHalls() {
  get('/service/games/byHall')
    .then((response) => allGamesByHall = response.data);
}

function fetchPlayerData() {
  return get('/service/player');
}

function countGamesOnScreen() {
  const screenHeight = $(window).height();
  const screenWidth = $(window).width();
  const countVertical = Math.round(screenHeight / 130);
  /*
    var gameHeight = $('.game').first().outerHeight();
    var gameWidth = $('.game').first().outerWidth();
    var countHorizontal = Math.round(screenHeight / gameHeight);
    var countVertical = Math.round(screenWidth / gameWidth);
    var gamesCountOnScreen = countHorizontal * countVertical;
  */
  let countHorizontal;
  if (screenWidth <= 540) {
    countHorizontal = 2;
  }
  if (screenWidth <= 810 && screenWidth > 540) {
    countHorizontal = 4;
  }
  if (screenWidth <= 1340 && screenWidth > 810) {
    countHorizontal = 5;
  }
  if (screenWidth > 1340) {
    countHorizontal = Math.round(screenWidth / 253);
  }
  const gamesCountOnScreen = countHorizontal * countVertical;

  return gamesCountOnScreen;
}

function windowScrolling(event) {
  const isAllGamesHall = document
    .querySelector('.slider-menu__item[data-hall="all-games"]')
    .classList.contains('slick-current');
  if ($(window).scrollTop() + $(window).height() >= $(document).height() - 400 && isAllGamesHall) {
    if (lastGame >= allGamesArray.length) {
      $(window).off('scroll', windowScrolling);
      $(window).off('resize', windowResize);
    } else {
      loadSliceGames(lastGame, lastGame + countGames); // add games by scrolling
    }
  }
}

function windowResize(event) {
  if (allGamesArray) {
    const gamesCountOnScreen = countGamesOnScreen();
    const allGamesContainer = document.querySelector('#all-games .game-hall__wrapp');
    const allGamesLength = allGamesContainer.children.length;
    if (allGamesLength < gamesCountOnScreen) {
      countGames = gamesCountOnScreen;
      loadSliceGames(lastGame, lastGame + countGames); // add games by resize
    }
  }
}

function loadSliceGames(begin, end) {
  const $container = $('#all-games .game-hall__wrapp');
  allGamesArray.slice(begin, end).forEach((game) => {
    const $pattern = renderGame(game);
    $container.append($pattern);
  });
  lastGame = end;
  openGamePopup();
  onFavourClick();
}

export function loadGamesForHall(hall) {
  const $container = $(`#${hall} .game-hall__wrapp`);
  const hallGamesList = allGamesByHall[hall];

  if ($container.children().length === 0 && hallGamesList && hallGamesList.length > 0) {
    hallGamesList.forEach((game) => {
      const $pattern = renderGame(game);
      $container.append($pattern);
    });

    openGamePopup();
    onFavourClick();
  }
}

function requestInsertFavouriteGames() {
  if (!favLoaded) {
    insertFavouriteGames();
    favLoaded = true;
  }
}

export function insertFavouriteGames() {
  const emptyMsg = '<p class="fav-list-empty">Добавьте любимые игры</p>';
  const favouriteArray = fetchPlayerData();
  const $container = $('#favourite-games .game-hall__wrapp');
  favouriteArray
    .then((res) => {
      const favoriteGames = res.data.favoriteGames;
      $container.html('');
      if (favoriteGames.length > 0) {
        favoriteGames.forEach((favouriteGame) => {
          if (favouriteGame.mobile) {
            const $pattern = renderGame(favouriteGame);
            $container.append($pattern);
          }
        });
      } else {
        $container.append(emptyMsg);
      }
      if ($container.children().length === 0) {
        $container.append(emptyMsg);
      }
    })
    .then(() => {
      openGamePopup();
      onFavourClick();
      if (window.userLogged && lastGame < allGamesArray.length) {
        // needs to removeGame/cloneGame fav functions (load allgame list)
        loadSliceGames(lastGame, allGamesArray.length);
      }
    });
}
