import 'magnific-popup';
import onChangePassSubmit from '../../blocks/modules/_common/form/_recover-pass/form_recover-pass';
import { getUrlParameter, localStorageOpearate } from '../utils';
import { openDynamicPopup } from '../../blocks/modules/_common/popup/popup';
import { onInputFocus, onInputTooltipFocus } from '../../blocks/modules/_common/form/form';
import { getAllGameList, getGameListByHalls } from '../../blocks/modules/_common/game-hall/game-hall';
import searchGames from '../../blocks/modules/_common/search-games/search-games';


function checkParams() {
  const forgot = getUrlParameter('forgotCode');
  $('#forgotCode_input').val(forgot);
  if (forgot && forgot !== '') {
    $.magnificPopup.open({
      items: {
        src: '#change-pass',
      },
      callbacks: {
        open: () => document.body.classList.add('modal-opened'),
        close: () => document.body.classList.remove('modal-opened'),
      },
    });
  }

  const confirmation = getUrlParameter('confirmationCode');
  if (confirmation && confirmation !== '') {
      openInfoPopup('Подписка на рассылку', 'Поздравляем! Вы подписаны на рассылку.');
  }

  switch (getUrlParameter('emailVerification')) {
      case '': {
          break;
      }
      case 'ok': {
          openInfoPopup('Успешно', 'Ваш email подтвержден');
          break;
      }
      case 'no': {
          openInfoPopup('Ошибка', 'Подтвердить email не удалось');
          break;
      }
  }


   switch (getUrlParameter('transaction')) {
       case '': {
           break;
       }
       case 'ok': {
           openInfoPopup('Поздравляем!', 'Средства зачислены на ваш игровой счет');
           break;
       }
       case 'no': {
           openInfoPopup('Ошибка', 'Обратитесь в службу поддержки');
           break;
       }
   }

  const passwordChanged = localStorageOpearate('passwordChanged');
  if (passwordChanged && passwordChanged !== '') {
    openDynamicPopup('Успешно!', 'Пароль успешно изменен');
    localStorageOpearate('passwordChanged', 'remove');
  }
  if (window.userLogged) {
    const lotteyPopup = getUrlParameter('lotteryPopup');
    if (lotteyPopup && lotteyPopup !== ''){
      openDynamicPopup('Лотерея', 'Вы зарегистрировались в лотерее! Для того чтобы получать лотерейные билеты, достаточно просто играть в любимые автоматы! В лотерее участвуют все игровые автоматы');
    }
  }
}

onInputFocus();
onInputTooltipFocus();
onChangePassSubmit();
getAllGameList();
getGameListByHalls();
checkParams();
searchGames();
