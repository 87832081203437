export const ru = {
    translation: {
        RUB: "руб.",
        USD: "$",
        UAH: "грн",
    }
};

export const en = {
    translation: {
        RUB: "RUB",
        USD: "USD",
        UAH: "uah",
    }
};

export const resources = { ru, en };
