import axios from 'axios';
import { URL_SEARCH, URL_CLICK } from '../../../../js/constants';

class SendSearchStatistic {
  constructor() {
    this.urlSearch = URL_SEARCH;
    this.urlClick = URL_CLICK;
    this.delay = 3000;
  }

  isSearchActive = () => localStorage.getItem('search') === 'active';

  isHaveId = () => localStorage.getItem('searchID') !== null;

  clearStorage = item => localStorage.removeItem(item);

  sendStat = (click) => {
    
    if (!this.isSearchActive()) {
      return;
    }

    this.clearStorage('search'); 
    const query = document.querySelector('.search-games__input').value;
    const resultCount = document.getElementById('gamesCount').textContent;
    const sendingData = { query, resultCount };

    axios
      .post(this.urlSearch, sendingData)
      .then(response => response.data)
      .then(({data: id}) => {
        localStorage.setItem('searchID', id);
        if (click) this.sendStatClick(id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  sendStatClick = (id) => { 
    id = parseInt(id, 10);
    axios.post(this.urlClick, { id });
  };

  handleGameClick = (event) => {
    event.preventDefault();

    if (this.isHaveId()) {
      const id = localStorage.getItem('searchID');
      this.sendStatClick(id);
    } else {
      this.sendStat(true);
    }
  };
}

export default SendSearchStatistic;
