const onInputTooltipFocus = () => {
  document.addEventListener("DOMContentLoaded", function(){
    const inputs = [...document.querySelectorAll('.form-block__input_tooltip')];

    inputs.forEach((input) => {
      input.addEventListener('focus', () => {
        const tooltips = [...document.querySelectorAll('.form-block__tooltip_help')];

        tooltips.forEach((tooltip) => {
          tooltip.style.display = 'none';
        })

        $(input)
          .closest('.form-block__input-wrapp')
          .siblings('.form-block__tooltip_help')
          .show();
      });

      input.addEventListener('blur', () => {
        $(input)
          .closest('.form-block__input-wrapp')
          .siblings('.form-block__tooltip_help')
          .hide();
      });
    });
  });
};

const onInputFocus = () => {
  $('input, textarea').focus(function() {
    $(this).closest('.form-block').find('.form-block__tooltip_error').hide();
  });
}

const validateRules = () => {
  const rulesCheckers = [...document.querySelectorAll('input[id^="rules-check"]')];

  rulesCheckers.forEach((rulesCheck) => {
      rulesCheck.addEventListener('change', () => {
          const rulesError = document.createElement('span');
          rulesError.className = 'form-block__tooltip form-block__tooltip_error form-block__tooltip_rules';
          rulesError.textContent = 'Вы должны согласиться с правилами казино';
          if (rulesCheck.checked) {
              rulesCheck.classList.remove('form-block__checkbox_error');
              $('.form-block__tooltip_rules').remove();
          } else {
              rulesCheck.classList.add('form-block__checkbox_error');
              rulesCheck.parentNode.appendChild(rulesError);
          }
      });
  })
};

export {
  onInputTooltipFocus,
  onInputFocus,
  validateRules
};
