import { openDynamicPopup } from '../popup/popup';
import loadCaptcha from '../form/__captcha/form__captcha';

export function createValidationMessages(object, error, message) {
    if (!error) {
        $(object).addClass('form-block__input_success');
        $(object).parent().after('<span class="form-block__tooltip form-block__tooltip_help">' + message + '</span>');
    } else {
        $(object).addClass('form-block__input_error');
        $(object).parent().after('<span class="form-block__tooltip form-block__tooltip_visible form-block__tooltip_error">' + message + '</span>');
    }
}

export function validateLogin(login) {
    if (!login.value || login.value.length === 0) {
        showErrorMessages($(`#${login.id}`), 'Укажите email');
        return false;
    }

    return true;
}

function isPasswordNotEmpty(password) {
    if (!password.value || password.value.length === 0) {
        showErrorMessages(
            $(`#${password.id}`),
            'Укажите пароль'
        );
        return false;
    }

    return true;
}

function isPasswordValid(password) {
    const valid = /^[a-zA-Z0-9:@?~@$^&*()_; ]{8,256}$/.test(password.value);

    if (!valid) {
        showErrorMessages(
            $(`#${password.id}`),
            'Пароль не менее 8 символов.'
        );
        return false;
    }

    return true;
}

export const validatePassword = (password, type) =>
    type ? isPasswordNotEmpty(password) && isPasswordValid(password) : isPasswordNotEmpty(password);

export function validatePhone(phone) {

    if (!phone.value || phone.value.length === 0) {
        console.log($('#phone'));
        
        $('#phone')
          .closest('.form-block__input-block')
          .find('.form-block__tooltip_error')
          .text('Укажите номер телефона')
          .show()

        return false;
      }

    return true;
}

export function validatePhonePassword(password) {
    if (!password.value || password.value.length === 0) {
        showErrorMessages($(`#${password.id}`), 'Введите пароль');
        return false;
    }

    return true;
}


export function hideErrorMessage(el) {
    let error = el.parent().next();
    error.hide();
}

export function hideAllErrorMessage() {
    $('.form-block__input_error').removeClass('form-block__input_error');
    $('.form-block__tooltip_error').hide();
}

export function clearValidationMessages() {
    $('.form-block__input_error').removeClass('form-block__input_error');
    $('.form-block__tooltip_error').remove();
    $('.form-block__input_success').removeClass('form-block__input_success');
    $('.form-block__tooltip_success').remove();
}

export function showErrorMessages(object, message) {
    $(object)
        .addClass('form-block__input_error')
        .closest('.form-block__input-block')
        .find('.form-block__tooltip_error')
        .text(message)
        .show()
}

export function showCaptcha() {
    loadCaptcha();
    $('.form-block_captcha').show().removeClass('form-block_hidden');
}

export function loginAjax(loginData, autoLogin, phone) {
    const loginBtns = [...document.querySelectorAll('.js-auth')]

    loginBtns.forEach((btn) => btn.disabled = true)

    $.ajax({
        type: 'POST',
        url: "/service/player/login",
        data: JSON.stringify(loginData),
        dataType: "json",
        contentType: "application/json",
        headers: {
            'X-Requested-With': ''
        },
        success: function ({ data, error }) {
            if (data.loginSuccess) {
                location.replace('/');
            } else {
                if (autoLogin) {
                    location.href = '/login';
                }
                else {
                    $.each(error.items, function (i, val) {
                        if (i === 'captcha') {
                            loadCaptcha();
                            $('.form-block_captcha').show();
                            $(`#captcha`).addClass('form-block__input_error');
                            showErrorMessages($(`#captcha`), val);
                        }
                        else {
                            if (phone) {
                                $('#login-phone').addClass('form-block__input_error');
                                $('#password-phone').addClass('form-block__input_error');
                                showErrorMessages($('#password-phone'), val);
                            }
                            else {
                                $('#login').addClass('form-block__input_error');
                                $('#password').addClass('form-block__input_error');
                                showErrorMessages($('#password'), val);
                            }
                        }
                    });

                    if (data.needCaptcha) {
                        showCaptcha();
                    }
                }
            }

            loginBtns.forEach((btn) => btn.disabled = false)
        },
        error: function (request, status, error) {
            openDynamicPopup('Сбой сервера', 'Сбой сервера, попробуйте позже');
            loginBtns.forEach((btn) => btn.disabled = false)
        },
    });
}
