import axios from 'axios';
import { onFavourClick, openGamePopup, renderGame } from '../../_common/game/game';
import SendSearchStatistic from './search-stat';
import { URL_RESPONSE_GAME, SEARCH_MIN_COUNT } from '../../../../js/constants';


export default function searchGames() {
  const gamesHalls = $('.game-halls-wrapp');
  const providerSlider = document.querySelector('.providers-slider');
  const searchInput = document.querySelector('.search-games__input');
  const gamePopup = document.getElementById('game-popup');
  let gamesList = [];
  const $container = $('#search-games');
  let timeout = null;

  async function getGameList() {
    // if (!gamesList || gamesList.length == 0){
    try {
      const responseGame = await axios.get(URL_RESPONSE_GAME);
      gamesList = responseGame.data;
    } catch (error) {
      alert(error);
    }
    // }
  }
  const renderGamesCount = (count) => {
    const countGames = document.querySelector('#gamesCount');
    countGames.innerHTML = count
  };

  const clearSearchList = () => {
    $container
      .find('.game-hall__wrapp')
      .html('');
  }

  function renderGameList(request) {
    if (request) {
      $container.fadeIn();
      request = request.toLowerCase();
      clearSearchList()
      let countOfGames = 0;

      gamesList.forEach((game) => {
        if (
          game.name.toLowerCase().includes(request) ||
          game.tags.toLowerCase().includes(request)
        ) {
          const $pattern = renderGame(game);

          $container
            .find('.game-hall__wrapp')
            .append($pattern);

          countOfGames += 1;
        }
      });
      const searchResCount = renderGamesCount(countOfGames);
      $container.prepend(searchResCount);
      openGamePopup();
      onFavourClick();
    } else {
      clearSearchList()
    }
  }

  const sendSearch = new SendSearchStatistic();

  function gameClickHandler(event) {
    const isGameLink = event.target.classList.contains('play-game-btn');
    if (isGameLink) {
      sendSearch.handleGameClick(event);
    }
  }

  const toggleHallSearch = (toggle) => {
    if (toggle) {
      $container.fadeIn();
      gamesHalls.hide()
    } else {
      $container.hide()
      gamesHalls.fadeIn();
      sendSearch.clearStorage('searchID');
    }
  }

  function handleSearchInput(input) {

    if (input.value.length < SEARCH_MIN_COUNT) {
      renderGameList(false);
      toggleHallSearch(false)
      clearTimeout(timeout);
      return;
    } else {
      renderGameList(input.value);
      toggleHallSearch(true)
    }

    localStorage.setItem('search', 'active');
    sendSearch.clearStorage('searchID');

    if (timeout !== null) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      sendSearch.sendStat(false);
      clearTimeout(timeout);
    }, sendSearch.delay);
    
  }

  searchInput.addEventListener('focus', () => getGameList());
  searchInput.addEventListener('keyup', () => handleSearchInput(searchInput));
  gamePopup.addEventListener('click', e => gameClickHandler(e));
  providerSlider.addEventListener('click', () => toggleHallSearch(false));
}
