import { showErrorMessages, hideAllErrorMessage, loginAjax } from '../../login/login';
import loadCaptcha from '../__captcha/form__captcha';
import { localStorageOpearate, serializeForm } from '../../../../../js/utils';

export default function onChangePassSubmit() {
  serializeForm($);
  $('#form_restore_password-popup').submit(function (e) {
      e.preventDefault();
      hideAllErrorMessage();
      const data = $(this).serializeFormJSON();
      const loginData = $(this).serializeFormJSON();
      $.ajax({
          type: 'POST',
          url: "/service/player/restorePassword",
          data: JSON.stringify(data),
          dataType: "json",
          contentType: "application/json",
          success: function (msg) {
              if (!msg.error) {
                  loginData.login = msg.data;
                  localStorageOpearate('passwordChanged', 'set', 'true');
                  loginAjax(loginData);
              } else {
                  $.each(msg.error.items, function (i, val) {
                      showErrorMessages($('#new-pass'), val);
                  });
              }
              loadCaptcha();
          },
          error: function (request, status, error) {
              console.log(error);
              $.each(request.error.items, function (i, val) {
                  showErrorMessages($('#new-pass'), val)
              });
              loadCaptcha();
          },
      });
  });
}
